<template>
  <team-member-profile
    name="Adrian Yeoman"
    qualifications="BSc (Hons)"
    charter="MCSP"
    registration="HCPC Registered: PH: 29116"
    :pic="require(`@/assets/images/adrian.jpg`)"
  >
    <p>
      Adrian qualified as a physiotherapist in 1981 and has worked within the
      NHS for most of his career apart from a few years when he worked abroad in
      developing countries.
    </p>
    <p>
      He has been employed by Solent NHS Trust as a Spinal Clinical
      Physiotherapy Specialist since 2002. Alongside his spinal work he
      developed a special interest in the treatment of vestibular disorders,
      especially vestibular rehabilitation and it is in this capacity as a
      Vestibular Physiotherapist that he is working as part of the Balance
      Neurophysiotherapy Team.
    </p>
    <p>
      He has treated patients referred by the Audio-Vestibular Consultants at
      Queen Alexandra Hospital in Portsmouth. He has received extensive post
      graduate training and lectured throughout the UK to physiotherapists on
      the treatment of BPPV (Benign Paroxysmal Positional Vertigo). Adrian is a
      member of the physiotherapy special interest group ACPIVR (Association of
      Chartered Physiotherapists Interested in Vestibular Rehabilitation).
    </p>
    <p>
      Ongoing dizziness from vestibular problems can be very disabling and
      treating patients in their own homes with tailored exercises can greatly
      speed recovery. Adrian can help patients by setting realistic short and
      long term goals for rehabilitation.
    </p>
    <p>
      In his spare time Adrian is kept busy running a local Cub Scout group,
      playing guitar and harmonica and spending quality time with his three
      children and wife Karen.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Adrian",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
